import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/twitter.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/custom/expandable-text.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/custom/link.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/audio-slider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/count-down.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/fcm-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VisibilityWrapper"] */ "/vercel/path0/components/partials/footer/main-footer/visibility-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/footer/mobile-footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/footer/mobile-footer/magazine.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/header/auth-header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/header/magazine-header/actions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/header/magazine-header/corner-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/partials/header/magazine-header/header-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/header/main-header/actions.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/header/main-header/search.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/infinity-scroll-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/load-more.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/result/error-result.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/section-title.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/single-item/article-item.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/partials/single-item/podcast-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/context/audio-player.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/context/auth.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/context/cart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/lib/dayjs.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/vercel/path0/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
